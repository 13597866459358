.inner {
  width: 100%;
  max-width: var(--innerWidth);
  margin: 0 auto;
  padding: 0 var(--pad-inner);
}
.inner-2 {
  width: 100%;
  max-width: var(--innerWidth);
  margin: 0 auto;
  padding: 0 var(--pad-inner-2);
}

.inner-child {
  width: 100%;
  max-width: var(--innerWidth);
  margin: 0 auto;
  padding: 0 var(--pad-inner-child);
}
.inner-large {
  width: 100%;
  max-width: var(--innerWidth);
  margin: 0 auto;
  padding: 0 var(--pad-inner-large);
}
.inner-article {
  width: 100%;
  max-width: var(--innerWidth);
  margin: 0 auto;
  padding: 0 var(--pad-inner-article);
}
.ama-label {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.ama-section-title {
  font-family: var(--font-family);
  color: var(--primary-color);
  font-weight: 400;
  font-size: 3rem;
  line-height: 3.8125rem;
}
.ama-title {
  font-family: var(--font-family);
  color: var(--yellow-1);
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 3.156rem;
}
.ama-sub-title {
  font-family: var(--font-family);
  font-weight: 330;
  line-height: 1.875rem;
  font-size: 1.5rem;
  color: var(--c-1);
}
.ama-content {
  font-family: var(--font-family);
  font-weight: 390;
  color: var(--c-1);
  font-size: 1.125rem;
  line-height: 1.5625rem;
}

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";
@import "@gstyles/tailwind/style.scss";
@import "vendor/react-modal.scss";
@import "vendor/react-datepicker.scss";
@import "vendor/locomotive.scss";

:root {
  --max-width: 1440px;
  --border-radius: 12px;
  --font-family: "Brandon Text", "Roboto Mono", "Courier New", sans-serif;
  --font-family-secondary: "UTM Times", sans-serif;

  --font-size-title: 56px;
  --line-height-title: 76px;
  --innerWidth: 1920px;

  --gap-pad: 4.5rem;

  --pad-inner: 64px;
  --pad-inner-2: 48px;
  --pad-inner-child: 56px;
  --pad-inner-large: 180px;
  --pad-inner-article: 240px;

  --header-height: 164px;
  --header-child-height: 40px;
  --header-logo-height: 88px;
  --header-navs-height: 56px;
  --text-header: 14px;

  --yellow-1: #ae905e;
  --primary-color: #f7eedf;
  --c-1: #f6eedf;
  --white-1: #e8e7e6;
  --bg-black-color: #000;
  --black-1: #2e281f;
  --bg-white-color: #fff;
  --white-0: #fbfaf9;
  --error-color: #dc2626;

  //
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  // letter-spacing: 10%;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  font-family: var(--font-family);
  color: var(--primary-color);
}

h1 {
  font-size: 2rem;
  line-height: 3.156rem;
  font-weight: 500;
}

h2 {
  font-size: 1.6rem;
  line-height: 2.5rem;
  font-weight: 500;
}

h3 {
  font-size: 1.2rem;
  line-height: 1.8934rem;
  font-weight: 450;
}

h4 {
  font-size: 0.9rem;
  line-height: 1.5625rem;
  font-weight: 450;
}

h5 {
  font-size: 0.8rem;
  line-height: 1.4rem;
  font-weight: 400;
}

h6 {
  font-size: 0.7rem;
  line-height: 1.25rem;
  font-weight: 400;
}

a {
  color: inherit;
  text-decoration: none;
}

.no-padding-top {
  padding-top: 0px !important;
}

.article-box {
  @apply grid grid-cols-3 gap-10;
  padding-top: 3rem;
  padding-bottom: 3.75rem;
  .article {
    @apply flex flex-col;
    .image {
      width: 100%;
      aspect-ratio: 339 / 240;
    }
    .box {
      @apply flex flex-col justify-between flex-1;
      padding-top: 1.5rem;
      .category {
        @apply text-yellow-1 uppercase;
        font-size: 0.75rem;
        line-height: 1.0625rem;
        letter-spacing: 0.1em;
      }
      .title {
        font-size: 1.125rem;
        line-height: 1.5625rem;
        padding: 0.5rem 0;
      }
      .description {
        font-size: 0.875rem;
        line-height: 1rem;
        padding-bottom: 1.5rem;
      }
    }
  }
  &.light {
    .article {
      .box {
        .category {
          color: var(--yellow-1);
        }
        .title {
          color: var(--black-1);
        }
        .description {
          color: var(--black-1);
        }
        .button {
          color: var(--black-1);
          svg {
            path {
              fill: var(--black-1);
            }
          }
        }
      }
    }
  }
}
.ama-post {
  margin-bottom: var(--pad-inner-child);
  width: 100%;
  .head {
    @apply flex justify-between uppercase;
    margin-bottom: 1rem;
    .label,
    .date {
      font-size: 0.75rem;
      line-height: 1.0625rem;
      font-weight: 400;
    }
    .label {
      color: var(--yellow-1);
    }
    .date {
      color: var(--c-1);
    }
  }
  .title {
    padding-top: 1rem;
    font-family: var(--font-family-secondary);
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.8934rem;
    color: var(--c-1);
  }
  .description {
    padding-top: 1rem;
    font-family: var(--font-family);
    font-weight: 400;
    color: var(--c-1);
    font-size: 1rem;
    line-height: 1.4rem;
    opacity: 0.6;
  }

  .button {
    margin-top: 1rem;
  }
  &.vertical {
    // max-width: 26.875rem;
    .ama-img {
      width: 100%;
      aspect-ratio: 429 / 462;
    }
    .wrapper {
      width: 100%;
      aspect-ratio: 429 / 462;
      .img {
        // aspect-ratio: 429 / 462;
      }
    }
  }
  &.horizontal {
    // max-width: 33.5rem;
    .ama-img {
      width: 100%;
      aspect-ratio: 533 / 300;
    }
    .wrapper {
      width: 100%;
      aspect-ratio: 533 / 300;
      .img {
        // aspect-ratio: 533 / 300;
      }
    }
  }
}
.divider {
  width: 100%;
  border-bottom: 1px solid var(--white-1);
  padding-top: 4.5rem;
  margin-bottom: 4.5rem;
  opacity: 0.3;
}

// .inner-left {
//   width: 100%;
//   max-width: var(--innerWidth);
//   margin: 0 auto;
//   padding-left: var(--pad-inner-large);
// }

@media (max-width: 1023px) {
  :root {
    --pad-inner: 16px;
  }
}

.select {
  width: 150px;
  height: 40px;
  cursor: pointer;
  background-color: var(--bg-black-color);
  border-radius: 2px;
  position: relative;

  &_expand {
    width: 0;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;

    &::after {
      content: "\003E";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-50%, -50%) rotate(90deg) scaleY(1.75);
      color: var(--primary-color);
      font-size: 12px;
      pointer-events: none;
      z-index: 2;
      transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
    }

    &:checked::after {
      transform: translate(-50%, -50%) rotate(90deg) scaleX(-1) scaleY(1.75);
    }
  }

  &_expandLabel {
    display: block;
    width: 100%;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  &_close {
    display: none;
  }

  // &_closeLabel {
  //   width: 100vw;
  //   height: 100vh;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   display: none;
  // }

  &_items {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 40px;
  }

  &_input {
    display: none;
  }

  &_label {
    transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
    display: block;
    height: 0;
    font-size: 12px;
    line-height: 40px;
    overflow: hidden;
    color: var(--primary-color);
    background-color: var(--yellow-1);
    cursor: pointer;
    padding-left: 16px;
    text-transform: uppercase;

    &-placeholder {
      height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--bg-black-color);
    }
  }

  &_expand:checked {
    + .select_closeLabel {
      display: block;

      + .select_options {
        .select_label {
          height: 40px;

          &:hover {
            background-color: #000;
          }
        }

        + .select_expandLabel {
          display: none;
        }
      }
    }
  }

  &_input:checked + .select_label {
    height: 40px;
    margin-top: -40px;
  }
}

@import "./home.scss";
@import "./story.scss";
@import "./offers.scss";
// @media (max-width: 1280px) {
// }
// @media (max-width: 1023px) {
// }
// @media (max-width: 576px) {
// }
@media (max-width: 1280px) {
  :root {
    --pad-inner: 60px;
    --pad-inner-2: 48px;
    --pad-inner-child: 56px;
    --pad-inner-large: 140px;
    --pad-inner-article: 180px;
  }
}
@media (max-width: 1023px) {
  :root {
    --pad-inner: 32px;
    --pad-inner-2: 24px;
    --pad-inner-child: 28px;
    --pad-inner-large: 70px;
    --pad-inner-article: 90px;
  }
}
@media (max-width: 576px) {
  :root {
    --pad-inner: 16px;
    --pad-inner-2: 16px;
    --pad-inner-child: 16px;
    --pad-inner-large: 16px;
    --pad-inner-article: 16px;
  }
  .homePage {
    .ama-title {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    .ama-content {
      font-size: 0.9375rem;
      line-height: 1.375rem;
    }
    .ama-section-title {
      font-size: 2.5rem;
      line-height: 2.8125rem;
    }
  }
  .ama-post {
    .title {
      font-size: 1.25rem;
      line-height: 1.5625rem;
    }
    .description {
      font-size: 0.9375rem;
      line-height: 1.375rem;
    }
  }
  .article-box {
    @apply grid-cols-1;
  }
}
// ANIMATION

// UNDERLINE

.amin-underline {
  @apply relative;
  &:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    left: 0;
    content: "";
    display: block;
    height: 1px;
    transform: scaleX(0);
    width: 100%;
    position: absolute;
    background: #fff;
    transform-origin: bottom right;
    transition: transform 0.4s ease 0s, left 0.4s ease 0s;
  }
  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}
